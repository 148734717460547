<template>
  <div>object add</div>
</template>

<script>
export default {
  name: "add",
  data() {
    return {};
  },
  methods: {},
};
</script>